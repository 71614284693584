<template>
  <div class="goodsOwnerManage">
    <div class="facilityBox">
      <!-- 表单区域 -->
      <div class="searchBox">
        <div style="font-weight: 700;font-size: 16px;margin:0 0 10px 0;" v-if="companyName">{{ companyName }}</div>
        <el-alert style="margin: 0 0 10px 0;" type="warning" :closable="false">
          <span slot="title">
            <p>温馨提示：您可以勾选多个对象（企业、个人或其他），点击“下载合同”按钮，会把该对象的所有合同全部下载。</p>
          </span>
        </el-alert>
        <el-form inline :model="searchForm" ref="searchForm">
          <el-form-item label="企业名称：" prop="EnterpriseFullName" label-width="85px">
            <el-input v-model="searchForm.EnterpriseFullName" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="LegalPersonlPhone">
            <el-input v-model="searchForm.LegalPersonlPhone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="签订日期：" prop="field3" label-width="85px">
            <el-date-picker v-model="searchForm.dateTime" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label-width="0px">
            <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
            <el-button type="primary" size="medium" @click="routerBack" icon="el-icon-arrow-left">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-tabs v-model="activePage" class="barWrap" @tab-click="cmptChange">
        <el-tab-pane label="服务商" name="0"></el-tab-pane>
        <el-tab-pane label="货主" name="1"></el-tab-pane>
        <el-tab-pane label="个人" name="2"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" @selection-change="tableSelectionChange"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }" width="200" v-loading="loading">
        <el-table-column type="selection" align="center" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" show-overflow-tooltip
          width="240"></el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" show-overflow-tooltip
          width="240"></el-table-column>
        <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="身份证号" width="160"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Phone" label="手机号" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="签订日期" width="160"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goFacility(scope.row)"
              icon="el-icon-tickets">管理电子合同</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination.total"></el-pagination>
    </div>
    <!-- 电子合同管理 -->
    <el-dialog class="addDialog" :visible.sync="flag.isExchange" width="700px">
      <span slot="title" class="dialog-title">
        管理电子合同
      </span>
      <el-alert style="margin-bottom:10px;" type="warning" :closable="false"
        title="温馨提示：请仔细管理好您和合作伙伴之间的各类合同；您仅可以管理自己方的合同">
      </el-alert>
      <el-table :data="pactlist" @selection-change="tableSelectionChange" height="286px"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading='pactLoading'>
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="ContractName" label="文件名">
        </el-table-column>
        <el-table-column align="center" prop="ModifyDate" label="修改时间" width="200">
        </el-table-column>
        <el-table-column align="center" label="操作" width="220" fixed="right">
          <template slot-scope="scope">
            <el-button style="padding:0;" v-if="scope.row.ContractMediumType == '01'">
              <div class="up" style="width: 56px;height: 32px;font-size: 12px;line-height: 32px;">
                <input style="width: 56px;height: 32px;" class="upBox" type="file" id="inp" ref="inputer"
                  @change="fileUpload($event, 'list', scope.row)"
                  accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/msword,application/xml-dtd" />
                更新
              </div>
            </el-button>
            <el-button type="success" size="small" @click="upPact(scope.row)">下载</el-button>
            <el-button type="danger" size="small" @click="delPact(scope.row)"
              v-if="scope.row.ContractMediumType == '01'">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="pactChange" :current-page.sync="pactData.page"
        :page-size="pactData.pagesize" layout="total, prev, pager, next, jumper" :total="pactData.total">
      </el-pagination>
      <el-alert type="warning" :closable="false">
        <span slot="title">
          <span> 1.请点击“上传”按钮，新增一份合作文件；</span><br />
          <span> 2.已上传文件，可以更新覆盖、下载到本地、或者删除；</span><br />
          <span> 3.推荐高清PDF扫描文件，请务必保持电子文件的清晰完整。</span><br />
        </span>
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <div class="up" style="text-align: center;margin:10px 48%;">
          <i class="el-icon-upload2"></i>
          <input class="upBox" type="file" id="inp" ref="inputer" @change="fileUpload($event, 'btn')" />
          上传合同
        </div>
      </span>
    </el-dialog>
  </div>
</template> 

<script>
import { getDataDict } from '@/api/common/common.js'
import { getSecPriseList, addPartner, removeSupplier, GetEnterAgentInfo } from '@/api/goodsOwner/serviceProviderCooperation/index'
import { addContract, upLoadContract, GetPartyBUserID, GetContractInfo, removeContract, GetContID } from '@/api/goodsOwner/pactAdmin/index'
import { getDriverInfo } from '@/api/transport/driverManage/index'
export default {
  props: ["userId", "companyName"],
  data() {
    return {
      // 发票类型
      options: [],
      // 是否显示电子合同
      flag: {
        isExchange: false
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索参数
      searchForm: {
        EnterpriseFullName: '', //企业名称
        dateTime: ['', ''], //合作起始日期
        InvoiceType: '', //票据类型
        LegalPersonlPhone: ''
      },
      //表格数据
      tableData: [],
      // 合同分页参数
      pactData: {
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      // 合同列表
      pactlist: [],
      // 查看合同
      SupplierUserID: '',
      // 服务商列表loading
      loading: false,
      // 合同列表loading
      pactLoading: true,
      // 默认tab栏
      activePage: '0',
    }
  },
  methods: {
    routerBack() {
      this.$router.push({
        path: "/platform/alluser/index",
        query: {
          urlType: this.$route.query.urlType
        }
      })
    },
    // // tab切换
    // cmptChange(e){
    //   this.pagination.page = 1
    //   this.activePage = e.index
    //   if(e.index == 2){
    //     // 获取司机数据
    //     this.getDriverInfo()
    //   }else if(e.index == 0){
    //     // 获取数据列表
    //     this.getSecPriseList()
    //   }else{
    //     this.GetEnterAgentInfo();
    //   }
    // },
    // // 获取代理商
    // GetEnterAgentInfo(){
    //   GetEnterAgentInfo({}).then(res=>{
    //     this.tableData = res.enterAgent
    //     this.pagination.total = 1
    //   }).finally(() => {
    //     this.loading = false;
    //   });
    // },
    // //获取司机信息
    // getDriverInfo() {
    //   let params = {
    //     pageSize: this.pagination.pagesize,
    //     pageIndex: this.pagination.page,
    //     name:this.searchForm.LegalPersonlPhone,
    //     type:1
    //   }
    //   getDriverInfo(params).then(res => {
    //     this.tableData = res.driver
    //     this.pagination.total = Number(res.totalRowCount)
    //   }).finally(() => {
    //     this.loading = false;
    //   })
    // },
    // // 查看合同
    // goFacility(item){
    //   this.pactLoading = true;
    //   this.flag.isExchange = true;
    //   GetPartyBUserID({UserID:item.UserID}).then(res=>{
    //     this.GetContractInfo();
    //   })
    // },
    // //tbale选中改变
    // tableSelectionChange(e) {
    //   this.selectedTableItemList = e
    // },
    // //分页控件页码change事件回调
    // handleCurrentChange(e) {
    //   this.loading = true
    //    this.pagination.page = e
    //   if(this.activePage == 2){
    //     // 获取司机数据
    //     this.getDriverInfo()
    //   }else if(this.activePage == 0){
    //     let params = {
    //       EnterpriseFullName:this.searchForm.EnterpriseFullName,
    //       InvoiceType:this.searchForm.InvoiceType,
    //       StartDatetime:this.searchForm.dateTime[0],
    //       EndDatetime:this.searchForm.dateTime[1],
    //       pageSize: this.pagination.pagesize,
    //       pageIndex: e,
    //       LegalPersonlPhone:this.searchForm.LegalPersonlPhone
    //     }
    //     getSecPriseList({Json:JSON.stringify(params)}).then(res => {
    //       this.tableData = res.equipments
    //       this.pagination.total = Number(res.totalRowCount)
    //     }).finally(() => {
    //       this.loading = false;
    //     })
    //   }
    // },
    // //搜索
    // search() {
    //   this.loading = true
    //   this.pagination.page = 1
    //   if(this.activePage == 2){
    //     // 获取司机数据
    //     this.getDriverInfo()
    //   }else{
    //     //搜索前初始化page
    //     let params = {
    //       EnterpriseFullName:this.searchForm.EnterpriseFullName,
    //       InvoiceType:this.searchForm.InvoiceType,
    //       StartDatetime:this.searchForm.dateTime[0],
    //       EndDatetime:this.searchForm.dateTime[1],
    //       pageSize: this.pagination.pagesize,
    //       pageIndex: 1,
    //       LegalPersonlPhone:this.searchForm.LegalPersonlPhone
    //     }
    //     getSecPriseList({Json:JSON.stringify(params)}).then(res => {
    //       this.tableData = res.equipments
    //       this.pagination.total = Number(res.totalRowCount)
    //     }).finally(() => {
    //       this.loading = false;
    //     })
    //   }
    // },
    // //清空
    // resetForm() {
    //   //搜索前初始化page
    //   this.searchForm = {
    //     EnterpriseFullName:'',
    //     InvoiceType:'',
    //     dateTime:['',''],
    //     LegalPersonlPhone:''
    //   }
    //   this.loading = true
    //   this.pagination.page = 1
    //   let data = this.searchForm
    //   if(this.activePage == 2){
    //     // 获取司机数据
    //     this.getDriverInfo()
    //   }else{
    //     data.pageSize = 10
    //     data.pageIndex = 1
    //     getSecPriseList({Json:JSON.stringify(data)}).then(res => {
    //       this.tableData = res.equipments
    //       this.pagination.total = Number(res.totalRowCount)
    //     }).finally(() => {
    //       this.loading = false;
    //     })
    //   }
    // },
    // //新增
    // add() {
    //   addPartner({UserID:this.partnerObj.UserID}).then(res => {
    //     this.pagination.page = 1
    //     this.searchForm = {
    //       EnterpriseFullName:'',
    //       InvoiceType:'',
    //       dateTime:['',''],
    //       LegalPersonlPhone:''
    //     }
    //     this.flag.showAddDialog = false
    //     this.getSecPriseList();
    //   })
    // },
    // //获取服务商的货主列表
    // getSecPriseList() {
    //   let data = {
    //     EnterpriseFullName:this.searchForm.EnterpriseFullName,
    //     InvoiceType:this.searchForm.InvoiceType,
    //     StartDatetime:this.searchForm.dateTime[0],
    //     EndDatetime:this.searchForm.dateTime[1],
    //     pageSize:this.pagination.pagesize,
    //     pageIndex:this.pagination.page,
    //     LegalPersonlPhone:this.searchForm.LegalPersonlPhone
    //   }
    //   getSecPriseList({Json:JSON.stringify(data)}).then(res => {
    //     this.tableData = res.equipments
    //     this.pagination.total = Number(res.totalRowCount)
    //   }).finally(() => {
    //     this.loading = false;
    //   })
    // },
    // // 获取合同列表
    // GetContractInfo(){
    //   let params = {
    //     pageSize:this.pactData.pagesize,
    //     pageIndex:this.pactData.page
    //   }
    //   GetContractInfo(params).then(res=>{
    //     this.pactlist = res.ContractInfo
    //     this.pactData.total = Number(res.totalCount)
    //   }).finally(() => {
    //     this.pactLoading = false;
    //   })
    // },
    // // 合同分页
    // pactChange(e){
    //   this.pactData.page = e;
    //   this.GetContractInfo();
    // },
    // // 下载合同
    // upPact(item){
    //   window.location.href = item.ContractUrl
    // }
  },
  created() {
    // 获取服务商列表
    this.getSecPriseList()
    // 获取发票类型
    getDataDict({ type: 20 }).then(res => {
      this.options = res.patterSetInfo
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';

.goodsOwnerManage {
  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    border-bottom: 1px solid $borderGray;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .btns {
    margin-bottom: 20px;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-item {
      width: 50%;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }

  .addDialog {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .up {
      width: 89px;
      height: 40px;
      cursor: pointer;
      position: relative;
      color: #fff;
      background: #409EFF;
      font-size: 14px;
      border-radius: 3px;
      line-height: 40px;

      .updiv {
        position: absolute;
        cursor: pointer;
      }

      .upBox {
        width: 89px;
        height: 40px;
        position: absolute;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }
    }

    .body {
      .el-autocomplete {
        width: 100%;
      }

      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
}</style>