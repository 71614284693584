<template>
    <div>
        <servePact :companyName="companyName" :userId="UserID" v-if="pageType == 'serve'"></servePact>
        <ownerPact :companyName="companyName" :userId="UserID" v-if="pageType == 'owner'"></ownerPact>
        <agencyPact :companyName="companyName" :userId="UserID" v-if="pageType == 'agency'"></agencyPact>
        <driverPact :companyName="companyName" :userId="UserID" v-if="pageType == 'driver'"></driverPact>
    </div>
</template>

<script>
import servePact from "@/components/businessCmpt/servePact";
import ownerPact from "@/components/businessCmpt/ownerPact";
import agencyPact from "@/components/businessCmpt/agencyPact";
import driverPact from "@/components/businessCmpt/driverPact";
export default {
    data(){
        return{
            // 用户ID
            UserID:'',
            // 页面状态
            pageType:'',
            // 公司名称
            companyName:'',
            // tab状态
            urlType:"",
            pageIndex:1
        }
    },
    created() {
        this.pageIndex = this.$route.query.pageIndex?this.$route.query.pageIndex:1
        this.UserID = this.$route.query.UserID
        this.pageType = this.$route.query.pageType
        this.companyName = this.$route.query.companyName
        this.urlType = this.$route.query.urlType
    },
    methods:{

    },
    components: {
        servePact,
        ownerPact,
        agencyPact,
        driverPact
    },
}
</script>

<style lang="scss" scoped>

</style>