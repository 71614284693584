<template>
  <div class="goodsOwnerManage">
    <div class="facilityBox">
      <!-- 表单区域 -->
      <div class="searchBox">
        <div style="font-weight: 700;font-size: 16px;margin:0 0 10px 0;" v-if="companyName">{{companyName}}</div>
        <el-alert style="margin: 0 0 10px 0;" type="warning" :closable="false">
          <span slot="title">
            <p>温馨提示：您可以勾选多个对象（企业、个人或其他），点击“下载合同”按钮，会把该对象的所有合同全部下载。</p>
          </span>
        </el-alert>
        <el-form inline :model="searchForm" ref="searchForm">
          <el-form-item label="企业名称：" prop="EnterpriseFullName" label-width="85px">
            <el-input v-model="searchForm.EnterpriseFullName" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="签订日期：" prop="field3" label-width="85px">
            <el-date-picker
              v-model="searchForm.dateTime"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item  label-width="0px">
            <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
            <el-button type="primary" size="medium" :disabled="selectedTableItemList == ''?true:false" @click="downloadPact()" :icon="downloadIcon">下载合同</el-button>
            <el-button type="primary" size="medium" @click="routerBack" icon="el-icon-arrow-left">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-tabs v-model="activePage" class="barWrap" @tab-click="cmptChange">
        <el-tab-pane label="货主" name="0"></el-tab-pane>
        <el-tab-pane label="服务商" name="1"></el-tab-pane>
        <el-tab-pane label="其他" name="2"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" @selection-change="tableSelectionChange"
      :header-cell-style="{background:'#f0f0f0',color:'#666'}" width="200" v-loading="loading">
        <el-table-column type="selection" align="center" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CompanyName" label="企业名称" show-overflow-tooltip width="240"></el-table-column>
        <el-table-column align="center" prop="CompanyCode" label="企业统一社会信用代码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CompanyAddress" label="企业地址" show-overflow-tooltip width="240"></el-table-column>
        <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="身份证号" width="160" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Phone" label="手机号" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="签订日期" width="160" show-overflow-tooltip></el-table-column>
        <el-table-column align="center"  label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-tickets">管理电子合同</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper" :total="pagination.total"></el-pagination>
    </div>
    <!-- 电子合同管理 -->
    <el-dialog class="addDialog" :visible.sync="flag.isExchange" width="700px">
      <span slot="title" class="dialog-title">
        管理电子合同
      </span>
      <el-table :data="pactlist" @selection-change="tableSelectionChange" height="286px"
       :header-cell-style="{background:'#f0f0f0',color:'#666'}" v-loading='pactLoading'>
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="ContractName" label="文件名">
        </el-table-column>
        <el-table-column align="center" prop="ModifyDate" label="修改时间" width="200">
        </el-table-column>
        <el-table-column align="center"  label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="success" size="small" @click="upPact(scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="pactChange"
      :current-page.sync="pactData.page" :page-size="pactData.pagesize" layout="total, prev, pager, next, jumper" :total="pactData.total">
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import {getDataDict} from '@/api/common/common.js'
import {downloadContract} from '@/api/goodsOwner/serviceProviderCooperation/index'
import {GetPartyBUserID,GetContractInfo,driverContract} from '@/api/goodsOwner/pactAdmin/index'
export default {
  props: ["userId","companyName"],
  data() {
    return {
      // 发票类型
      options: [],
      // 是否显示电子合同
      flag: {
        isExchange:false
      },
      //分页控件相关参数
      pagination: { 
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索参数
      searchForm: {
        EnterpriseFullName: '', //企业名称
        dateTime: ['',''], //合作起始日期
      },
      //表格数据
      tableData: [], 
      // 合同分页参数
      pactData:{
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      // 合同列表
      pactlist:[],
      // 查看合同
      SupplierUserID:'',
      // 服务商列表loading
      loading:true,
      // 合同列表loading
      pactLoading:true,
      // TAB默认
      activePage:'0',
      // 下载的合同
      selectedTableItemList:'',
      // 是否为下载状态
      downloadIcon:'el-icon-download',
      partyBUserID:''
    }
  },
  methods: {
    routerBack() {
      this.$router.push({
        path: "/platform/alluser/index",
        query: {
          urlType: this.$route.query.urlType
        }
      })
    },
    // 下载合同
    downloadPact(){
      this.downloadIcon = 'el-icon-loading'
      let lng = this.selectedTableItemList.length
      let data = {
        PartyUserID:this.userId,
        DownloadUserIDs:[]
      }
      this.selectedTableItemList.forEach((item,index) => {
        let params = {
          downLoadUserID:item.CompanyUserId
        }
        data.DownloadUserIDs.push(params)
        if(index+1 == lng){
          downloadContract({Json:JSON.stringify(data)}).then(res=>{
            window.location.href = res.pathName
          }).finally(() => {
            this.downloadIcon = 'el-icon-download';
          })
        }
      });
    },
    // tab切换
    cmptChange(e){
      this.pagination.page = 1
      this.activePage = e.index
      // 获取数据列表
      this.getSecPriseList()
    },
    // 查看合同
    goFacility(item){
      this.pactLoading = true;
      this.flag.isExchange = true;
      this.partyBUserID = item.UserID
      this.GetContractInfo();
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
       this.pagination.page = e
      if(this.activePage == 2){
        // 获取司机数据
        this.getDriverInfo()
      }else if(this.activePage == 0){
        this.getSecPriseList();
      }
    },
    //搜索
    search() {
      this.loading = true
      this.pagination.page = 1
      // 获取数据列表
      this.getSecPriseList()
    },
    //清空
    resetForm() {
      //搜索前初始化page
      this.searchForm = {
        EnterpriseFullName:'',
        dateTime:['','']
      }
      this.loading = true
      this.pagination.page = 1
      // 获取数据列表
      this.getSecPriseList()
    },
    //获取服务商的货主列表
    getSecPriseList() {
      let data = {
        Search:this.searchForm.EnterpriseFullName,
        StartDatetime:this.searchForm.dateTime[0],
        EndDatetime:this.searchForm.dateTime[1],
        pageSize:this.pagination.pagesize,
        pageIndex:this.pagination.page,
        userId:this.userId,
        userType:Number(this.activePage) + 1
      }
      driverContract(data).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    // 获取合同列表
    GetContractInfo(){
      let params = {
        pageSize:this.pactData.pagesize,
        pageIndex:this.pactData.page,
        PartyUserID:this.userId,
        partyBUserID: this.partyBUserID,
      }
      GetContractInfo(params).then(res=>{
        this.pactlist = res.ContractInfo
        this.pactData.total = Number(res.totalCount)
      }).finally(() => {
        this.pactLoading = false;
      })
    },
    // 合同分页
    pactChange(e){
      this.pactData.page = e;
      this.GetContractInfo();
    },
    // 下载合同
    upPact(item){
      window.location.href = item.ContractUrl
    }
  },
  created() {
    // 获取服务商列表
    this.getSecPriseList()
    // 获取发票类型
    getDataDict({type: 20}).then(res => {
      this.options = res.patterSetInfo
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
.goodsOwnerManage {
  .el-form-item{
    margin-bottom: 14px;
  }
  .head {
    border-bottom: 1px solid $borderGray;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .btns {
    margin-bottom: 20px;
  }
  .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-item {
      width: 50%;
    }
    .el-date-editor {
      width: 100% !important;
    }
  }
  .pagination {
    margin-top: 10px;
  }
  .addDialog {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    ::v-deep .el-dialog__footer{
      border-top: 1px solid #eee;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
    .up{
      width: 89px;
      height: 40px;
      cursor: pointer;
      position: relative;
      color: #fff;
      background: #409EFF;
      font-size: 14px;
      border-radius: 3px;
      line-height: 40px;
      .updiv{
        position: absolute;
        cursor: pointer;
      }
      .upBox{
        width: 89px;
        height: 40px;
        position: absolute;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
    .body {
      .el-autocomplete {
        width: 100%;
      }
      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>