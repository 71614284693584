import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 平台审核-服务商管理-列表
export const SupplierList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/SupplierList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 平台审核-代理商管理-列表
export const AgentList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/AgentList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询服务商费率
export const GetSupplierRate = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/RateNew/GetSupplierRate?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 查询代理费率
export const GetAgentRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/RateNew/GetAgentRate?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 查询费率历史记录
export const GetRateRecords = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/GetRateRecords`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 设置服务商费率
export const SetSupplierRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/SetSupplierRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 设置代理费率
export const SetAgentRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/SetAgentRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 平台--同意服务商/代理商费率
export const AgreeRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/AgreeRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 平台--拒绝服务商/代理商费率
export const RejectRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/RejectRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 服务商/代理商--同意合作
export const CooperateRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/CooperateRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 代理专项设置--查询服务商集合
export const GetSupplierAll = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/GetSupplierAll`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 代理专项设置--查询已设置服务商列表
export const GetAgentExtSupplier = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/GetAgentExtSupplier`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 代理专项设置--设置服务商费率
export const SetAgentExtRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/SetAgentExtRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 代理专项设置--删除服务商费率
export const DelAgentExtRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/DelAgentExtRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 代理专项设置--查询服务商-代理设置的费率
export const GetAgentExtRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/GetAgentExtRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询货主费率
export const GetOwnerRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/RateNew/GetOwnerRate?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 设置货主费率
export const SetOwnerRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/SetOwnerRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询企业费率设置状态
export const GetRateOptionStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/RateNew/GetRateOptionStatus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 查询货主合作伙伴
export const GetPartners = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/GetPartners`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询服务商合作伙伴
export const GetPartnersBySupplier = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/GetPartnersBySupplier`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 添加货主合作伙伴
export const AddPartners = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/AddPartners`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除货主合作伙伴
export const DelPartners = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/DelPartners`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询货主-服务商费率
export const GetOwnerPartnersRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/RateNew/GetOwnerPartnersRate`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}