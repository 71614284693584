import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 新增合同
export const addContract = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/UpLoadAddContract`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 更新合同
export const upLoadContract = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/UpLoadUpdateContract`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取合同列表
export const GetContractInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/GetContractInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除合同
export const removeContract = (params) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${host}/api/Provider/RemoveContract?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 更新合同上传id
export const GetContID = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Provider/GetContID?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const GetPartyBUserID = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Provider/GetPartyBUserID?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 司机获取服务商
export const driverContract = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Applet/Driver/Contract`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取其他合同
export const getContractList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/GetContractList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取司机列表
export const GetContractDriverList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/GetContractDriverList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询货主委托协议
export const GetPCT10ContractList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/GetPCT10ContractList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
