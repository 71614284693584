<template>
  <div class="goodsOwnerManage">
    <div class="facilityBox">
      <!-- 表单区域 -->
      <div class="searchBox">
        <div style="font-weight: 700;font-size: 16px;margin:0 0 10px 0;" v-if="companyName">{{ companyName }}</div>
        <el-alert style="margin: 0 0 10px 0;" type="warning" :closable="false">
          <span slot="title">
            <p>温馨提示：您可以勾选多个对象（企业、个人或其他），点击“下载合同”按钮，会把该对象的所有合同全部下载。</p>
          </span>
        </el-alert>
        <el-form inline :model="searchForm" ref="searchForm">
          <el-form-item label="企业名称：" prop="EnterpriseFullName" label-width="85px" v-if="activePage != 2">
            <el-input v-model="searchForm.EnterpriseFullName" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="LegalPersonlPhone" v-if="activePage == 2">
            <el-input v-model="searchForm.LegalPersonlPhone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="签订日期：" prop="field3" label-width="85px" v-if="activePage != 2">
            <el-date-picker v-model="searchForm.dateTime" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label-width="0px">
            <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
            <el-button type="primary" size="medium" :disabled="selectedTableItemList == '' ? true : false"
              @click="downloadPact()" :icon="downloadIcon">下载合同</el-button>
            <el-button type="primary" size="medium" @click="routerBack" icon="el-icon-arrow-left">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-tabs v-model="activePage" class="barWrap" @tab-click="cmptChange">
        <el-tab-pane label="服务商" name="0"></el-tab-pane>
        <el-tab-pane label="代理商" name="1"></el-tab-pane>
        <el-tab-pane label="个人" name="2"></el-tab-pane>
        <el-tab-pane label="其他" name="3"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" @selection-change="tableSelectionChange"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }" width="200" v-loading="loading">
        <el-table-column type="selection" align="center" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" show-overflow-tooltip width="240">
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" show-overflow-tooltip width="240">
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip
          v-if="activePage == 2"></el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="身份证号" show-overflow-tooltip v-if="activePage == 2">
        </el-table-column>
        <el-table-column align="center" prop="Phone" label="手机号" show-overflow-tooltip
          v-if="activePage == 2"></el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="签订日期" show-overflow-tooltip v-if="activePage != 0">
        </el-table-column>
        <el-table-column align="center" prop="CreatedTime" label="签订日期" show-overflow-tooltip v-else>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="330">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-tickets">管理合同
            </el-button>
            <el-button type="primary" size="small" @click="goContractList(scope.row)" icon="el-icon-tickets"
              v-if="activePage == 3">委托协议</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination.total"></el-pagination>
    </div>
    <!-- 电子合同管理 -->
    <el-dialog class="addDialog" :visible.sync="flag.isExchange" width="700px">
      <span slot="title" class="dialog-title">
        管理电子合同
      </span>
      <el-table :data="pactlist" @selection-change="tableSelectionChange" height="500px"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading='pactLoading'>
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="ContractName" label="文件名">
        </el-table-column>
        <el-table-column align="center" prop="ModifyDate" label="修改时间" width="200">
        </el-table-column>
        <el-table-column align="center" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="success" size="small" @click="upPact(scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="pactChange" :current-page.sync="pactData.page"
        :page-size="pactData.pagesize" layout="total, prev, pager, next, jumper" :total="pactData.total">
      </el-pagination>
    </el-dialog>
    <!-- 委托协议 -->
    <el-dialog class="addDialog" :visible.sync="flag.contractListDialog" width="900px">
      <span slot="title" class="dialog-title">
        委托协议
      </span>
      <div style="display: flex;align-items:center;margin-bottom: 10px;">
        <span>合同名称：</span>
        <el-input v-model="name" placeholder="请输入合同名称" style="width:300px;margin: 0px 10px;"></el-input>
        <el-button type="primary" size="medium" @click="getPCT10ContractList" icon="el-icon-search">搜索</el-button>
      </div>
      <el-table :data="contractList" height="286px" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading='flag.contractListLoading'>
        <el-table-column align="center" type="index" prop="UserID" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="ContractName" label="文件名" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ModifyDate" label="修改时间" width="200">
        </el-table-column>
        <el-table-column align="center" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="success" size="small" @click="upPact(scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="contractChange" :current-page.sync="contractData.page"
        :page-size="contractData.pagesize" layout="total, prev, pager, next, jumper" :total="contractData.total">
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { GetEnterAgentInfo, downloadContract } from '@/api/goodsOwner/serviceProviderCooperation/index'
import { GetContractInfo, getContractList, GetContractDriverList, GetPCT10ContractList } from '@/api/goodsOwner/pactAdmin/index'
import { GetPartners } from '@/api/newRate'
export default {
  props: ["userId", "companyName"],
  data() {
    return {
      // 是否显示电子合同
      name: '',
      flag: {
        isExchange: false,
        contractListLoading: false,
        contractListDialog: false
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 搜索参数
      searchForm: {
        EnterpriseFullName: '', //企业名称
        dateTime: ['', ''], //合作起始日期
        InvoiceType: '', //票据类型
        LegalPersonlPhone: ''
      },
      //表格数据
      tableData: [],
      // 合同分页参数
      pactData: {
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      // 合同列表
      pactlist: [],
      // 查看合同
      SupplierUserID: '',
      // 服务商列表loading
      loading: true,
      // 合同列表loading
      pactLoading: true,
      // TAB默认
      activePage: '0',
      // 下载的合同
      selectedTableItemList: '',
      // 是否为下载状态
      downloadIcon: 'el-icon-download',
      contractList: [],
      contractData: {
        page: 1, //当前所处的页码
        pagesize: 7, //每次请求的数量
        total: 0, //总条数
        contractUserId: ''
      },
      partyBUserID: ''
    }
  },
  methods: {
    routerBack() {
      this.$router.push({
        path: "/platform/alluser/index",
        query: {
          urlType: this.$route.query.urlType
        }
      })
    },
    // 获取其他合同列表
    getContractList() {
      let data = {
        EnterpriseFullName: this.searchForm.EnterpriseFullName,
        InvoiceType: this.searchForm.InvoiceType,
        StartDatetime: this.searchForm.dateTime[0],
        EndDatetime: this.searchForm.dateTime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        PartyUserID: this.userId
      }
      getContractList({ Json: JSON.stringify(data) }).then(res => {
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    // 下载合同
    downloadPact() {
      this.downloadIcon = 'el-icon-loading'
      let lng = this.selectedTableItemList.length
      let data = {
        PartyUserID: this.userId,
        DownloadUserIDs: []
      }
      this.selectedTableItemList.forEach((item, index) => {
        let params = {
          downLoadUserID: item.UserID
        }
        data.DownloadUserIDs.push(params)
        if (index + 1 == lng) {
          downloadContract({ Json: JSON.stringify(data) }).then(res => {
            window.location.href = res.pathName
          }).finally(() => {
            this.downloadIcon = 'el-icon-download';
          })
        }
      });
    },
    // 获取委托协议
    goContractList(row) {
      this.contractList = []
      this.contractData.page = 1
      this.contractData.contractUserId = row.UserID
      this.flag.contractListDialog = true
      this.getPCT10ContractList()
    },
    getPCT10ContractList() {
      this.flag.contractListLoading = true
      let params = {
        pageSize: this.contractData.pagesize,
        pageIndex: this.contractData.page,
        userID: this.userId,
        name: this.name
      }
      GetPCT10ContractList(params).then(res => {
        this.contractList = res.data.DataList
        this.contractData.total = Number(res.data.TotalCount)
      }).finally(() => {
        this.flag.contractListLoading = false
      })
    },
    contractChange(e) {
      this.contractData.page = e;
      this.getPCT10ContractList();
    },
    // tab切换
    cmptChange(e) {
      this.pagination.page = 1
      this.activePage = e.index
      this.loading = true
      this.searchForm = {
        EnterpriseFullName: '',
        dateTime: ['', ''],
        LegalPersonlPhone: ''
      }
      if (e.index == 2) {
        // 获取司机数据
        this.getDriverInfo()
      } else if (e.index == 0) {
        // 获取数据列表
        this.getSecPriseList()
      } else if (e.index == 3) {
        // 获取数据列表
        this.getContractList()
      } else {
        this.GetEnterAgentInfo();
      }
    },
    // 获取代理商
    GetEnterAgentInfo() {
      GetEnterAgentInfo({ PartyUserID: this.userId }).then(res => {
        this.tableData = res.enterAgent
        this.pagination.total = 1
      }).finally(() => {
        this.loading = false;
      });
    },
    //获取司机信息
    getDriverInfo() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        name: this.searchForm.LegalPersonlPhone,
        type: 1,
        PartyUserID: this.userId
      }
      GetContractDriverList(params).then(res => {
        this.tableData = res.driver
        this.pagination.total = Number(res.totalRowCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    // 查看合同
    goFacility(item) {
      this.pactLoading = true;
      this.flag.isExchange = true;
      this.partyBUserID = item.UserID
      this.GetContractInfo();
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
      this.pagination.page = e
      if (this.activePage == 2) {
        // 获取司机数据
        this.getDriverInfo()
      } else if (this.activePage == 3) {
        // 获取数据列表
        this.getContractList()
      } else if (this.activePage == 0) {
        this.getSecPriseList();
      }
    },
    //搜索
    search() {
      this.loading = true
      this.pagination.page = 1
      if (this.activePage == 2) {
        // 获取司机数据
        this.getDriverInfo()
      } else if (this.activePage == 0) {
        this.getSecPriseList();
      } else if (this.activePage == 3) {
        // 获取数据列表
        this.getContractList()
      } else {
        this.loading = false
      }
    },
    //清空
    resetForm() {
      //搜索前初始化page
      this.searchForm = {
        EnterpriseFullName: '',
        dateTime: ['', ''],
        LegalPersonlPhone: ''
      }
      this.loading = true
      this.pagination.page = 1
      if (this.activePage == 2) {
        // 获取司机数据
        this.getDriverInfo()
      } else if (this.activePage == 0) {
        this.getSecPriseList();
      } else if (this.activePage == 3) {
        // 获取数据列表
        this.getContractList()
      } else {
        this.loading = false
      }
    },
    //获取服务商的货主列表
    getSecPriseList() {
      let params = {
        enterpriseFullName: this.searchForm.EnterpriseFullName,
        createdTimeStart: (this.searchForm.dateTime && this.searchForm.dateTime[0]) ? this.searchForm.dateTime[0] + ' 00:00:00' : '',
        createdTimeEnd: (this.searchForm.dateTime && this.searchForm.dateTime[1]) ? this.searchForm.dateTime[1] + ' 23:59:59' : '',
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        userID: this.userId
      }
      GetPartners(params).then(res => {
        this.tableData = res.data.DataList;
        this.pagination.total = res.data.TotalCount;
      }).finally(() => {
        this.loading = false;
      })
    },
    // 获取合同列表
    GetContractInfo() {
      let params = {
        pageSize: this.pactData.pagesize,
        pageIndex: this.pactData.page,
        PartyUserID: this.userId,
        partyBUserID: this.partyBUserID,
      }
      GetContractInfo(params).then(res => {
        this.pactlist = res.ContractInfo
        this.pactData.total = Number(res.totalCount)
      }).finally(() => {
        this.pactLoading = false;
      })
    },
    // 合同分页
    pactChange(e) {
      this.pactData.page = e;
      this.GetContractInfo();
    },
    // 下载合同
    upPact(item) {
      window.open(item.ContractUrl, '_blank')
    }
  },
  created() {
    // 获取服务商列表
    this.getSecPriseList()
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';

.goodsOwnerManage {
  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    border-bottom: 1px solid $borderGray;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .btns {
    margin-bottom: 20px;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-item {
      width: 50%;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }

  .addDialog {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .up {
      width: 89px;
      height: 40px;
      cursor: pointer;
      position: relative;
      color: #fff;
      background: #409EFF;
      font-size: 14px;
      border-radius: 3px;
      line-height: 40px;

      .updiv {
        position: absolute;
        cursor: pointer;
      }

      .upBox {
        width: 89px;
        height: 40px;
        position: absolute;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }
    }

    .body {
      .el-autocomplete {
        width: 100%;
      }

      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>