import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//服务商查询货主(分页)
export const getSecPriseList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/EquipmentsSupplierInfoList`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 服务商弹出框
export const GetEnterPriseList = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Provider/GetSupplierPopList?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//删除企业货主
export const removeSupplier = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/RemoveSupplier`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//添加服务商
export const addPartner = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Provider/AddSupplier?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 确认
export const updateSupplier = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Provider/UpdateSupplierStatus?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 判断货主费率是否合理
export const getConsignor = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Provider/GetConsignorTaskSceneList?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


// 
// 
//                         分割线
// 
// 服务商端接口

// 货主合作关系管理
export const equipmentsList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/EquipmentsList`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 设置费率
export const supplierRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Provider/UpdateSupplierRate?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查看已设置的费率
export const GetSupplierRate = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Provider/GetSupplierRate?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取服务商发票类型
export const InvoiceType = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Provider/GetSupplierInvoiceType`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}



// 
// 
//                         代理商端接口  分割线
// 
// 



// 货主获取代理商
export const GetEnterAgentInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetEnterAgentInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载合同
export const downloadContract = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/DownloadContract`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}